import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";
import TextPageLayout from "../../components/layouts/TextPageLayout";
import { WORKOUTS } from "../../models/workouts";

function WorkoutsPage() {
  return (
    <TextPageLayout title="Calisthenics Workouts">
      <SEO
        path="/workouts/workouts-list"
        title="Workouts"
        description="Pick a calisthenics workout to follow along to. Includes beginner workouts like the Recommended Routine."
      />
      <section style={{ textAlign: "left" }}>
        <h3>
          Choose a ready-made workout format or{" "}
          <Link to="/workouts">build your own</Link>
        </h3>
        <ul>
          {Object.entries(WORKOUTS).map(entry => {
            const [slug, name] = entry;
            return (
              <li key={slug}>
                <Link to={`/workouts/${slug}/`}>{name}</Link>
              </li>
            );
          })}
        </ul>
      </section>
    </TextPageLayout>
  );
}

export default WorkoutsPage;
